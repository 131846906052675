import React, { Component } from 'react'

class AdminLogin extends Component {

    constructor(props) {
        super(props)

        this.state = {
            email: "",
            password: ""
        }
    }

    onSubmit = async () => {
        console.log(this.state.email, this.state.password)
    }

    render() {
        return (
            <>
                <div>
                    <br />
                    Email:
                    <input
                        type="text"
                        onChange={item => this.setState({ email: item.eventPhase.value })}
                    />
                    <br />
                    password:
                    <input
                        type="password"
                        onChange={item => this.setState({ password: item.eventPhase.value })}
                    />
                    <br />
                </div>
                <button onClick={() => this.onSubmit()}>
                    Submit
                </button>
            </>
        )
    }
}

export default AdminLogin
