import { authHeader, handleResponse, handleResponse_ } from '../_helpers';
import { server_url } from '.';


export const bookingService = {
    getBookings,
    downloadPDF
};

function getBookings(data) {

    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({ data: data })
    };
    console.log(requestOptions);
    // return fetch(`http://localhost:8000/api/booking/get-booking`, requestOptions).then(handleResponse);
    return fetch(`${server_url}/api/booking/get-booking`, requestOptions).then(handleResponse);
}

export const createBooking = async (data) => {


    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    console.log(data);

    // return fetch(`http://localhost:8000/api/booking/get-booking`, requestOptions).then(handleResponse);
    return fetch(`${server_url}/api/booking/create-booking`, requestOptions).then(handleResponse_);
}

export const updateBooking = async (data) => {


    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    console.log(data);
    // return fetch(`http://localhost:8000/api/booking/get-booking`, requestOptions).then(handleResponse);
    return fetch(`${server_url}/api/booking`, requestOptions).then(handleResponse);
}

export const deleteSigleBooking = async (data) => {


    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    console.log(data);
    // return fetch(`http://localhost:8000/api/booking/get-booking`, requestOptions).then(handleResponse);
    return fetch(`${server_url}/api/booking/delete`, requestOptions).then(handleResponse);
}

function downloadPDF(data) {

    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({ data: data })
    };
    console.log(requestOptions);
    // return fetch(`http://localhost:8000/api/booking/get-booking`, requestOptions).then(handleResponse);
    return fetch(`${server_url}/api/booking/ticket`, requestOptions).then(handleResponse);
}
