import { server_url } from '.';
import { authHeader, handleResponse } from '../_helpers';

export const slotService = {
    getSlotsForDateRange
};

function getSlotsForDateRange(fromDate, toDate) {
    console.log(fromDate, toDate);
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({
            fromDate: fromDate,
            toDate: toDate,
        })
    };
    return fetch(`${server_url}/api/date/slots`, requestOptions).then(handleResponse);
}

export function getCalendarStatus(fromDate, toDate) {


    console.log(fromDate, toDate);
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({
            fromDate: fromDate,
            toDate: toDate,
        })
    };
    return fetch(`${server_url}/api/date/status`, requestOptions).then(handleResponse);
}

