import React, { Component } from 'react';
import './App.css';

import Router from './app/Router';
import Notifications from 'react-notify-toast';

class App extends Component {
  render() {
    return (
      <React.Fragment>
        <Notifications />
        <Router />
      </React.Fragment>
    );
  }
}

export default App;
