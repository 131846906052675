import React, { useState, useEffect } from "react";
import Modal from 'react-modal';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import { notify } from 'react-notify-toast';

import moment from "moment";
import { slotService, getCalendarStatus } from '../_services/slot.service';
import { bookingService, updateBooking, createBooking } from '../_services/booking.service';
import { navigate } from "@reach/router";
import { sendOTP } from "../_services";
import { setDate } from "date-fns";
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        border: 'none'
    }
};

export default function Darshan({ location }) {
    const IDProofs = [
        {
            name: 'Election card',
            value: 'ELECTIONCARD'
        },
        {
            name: 'Aadhar Card',
            value: 'AADHARCARD'
        },
        {
            name: 'Pan card',
            value: 'PANCARD'
        },
        {
            name: 'Driving Licence',
            value: 'DRIVINGLICENSE'
        },
        {
            name: 'Passport',
            value: 'PASSPORT'
        }
    ]

    const [startDate, setStartDate] = useState();
    const [bookings, setBookings] = useState([]);
    const [bookedByPhoneNumber, setBookedByPhoneNumber] = useState({
        value: '',
        error: ''
    });
    const [openDropDown, setOpenDropDown] = useState(false);
    const [createBookingParams, setCreateBookingParams] = useState('');

    const [slots, setSlots] = useState([]);

    const [name, setName] = useState({
        value: "",
        error: ''
    });
    const [phone, setPhone] = useState({
        value: "",
        error: ''
    });
    const [male, setMale] = useState(0);
    const [female, setFemale] = useState(0);
    const [children, setChildren] = useState(0);
    const [city, setCity] = useState({
        value: '',
        error: ''
    });
    const [idCardNUmber, setIdCardNUmber] = useState({
        value: '',
        error: ''
    });
    const [typeIDProof, setTypeIDProof] = useState({
        value: '',
        error: ""
    });
    const [bookingDate, setBookingDate] = useState({
        value: '',
        error: ""
    });
    const [selectedSlot, setSelectedSlot] = useState({
        value: '',
        error: ''
    });
    const [isOTPMOdal, setIsOTPMOdal] = useState(false);
    const [otp, setOTP] = useState('');

    const [isModalOpen, setModalOpen] = useState(false);
    const [maxDate, setMaxDate] = useState(false);

    const [personBlankError, setPersonBlankError] = useState(''); //error for person
    const [remainingDates, setRemainingDates] = useState([
        {
            "unavailable_date_style": []
        },
        {
            "not_applicable_date_style": []
        },
        {
            "available_date_style": []
        }
    ]);
    const [unavailableDates, setUnavailableDates] = useState([]);
    const [bookingId, setBookingId] = useState();

    //information pop up flow
    const [isInfoModal, setIsInfoModal] = useState(false);
    const [createdBookingResponse, setCreatedBookingResponse] = useState('');
    var isDropAction = true

    useEffect(async () => {
        try {
            const bookingId = window.location.pathname.split('/')[2];
            let response = await bookingService.getBookings(bookingId)

            if (response.result.length > 0) {
                let booking = response.result[0];
                console.log(booking)

                setName({ value: booking.name });
                setMale(booking.male)
                setFemale(booking.female)
                setChildren(booking.children)
                setPhone({ value: booking.bookedByPhoneNumber })
                setCity({ value: booking.city })
                setBookingDate({
                    value: moment(parseFloat(booking.slot.fromTime)).toDate()
                })
                setSelectedSlot({
                    value: booking.slot.id
                })
                setIdCardNUmber({
                    value: booking.idCardNumber
                })
                setTypeIDProof({
                    value: booking.idProofType
                })
                setBookingId(booking.id)
                await getSlots(moment(parseFloat(booking.slot.fromTime)).toDate());

                let fromDate = moment().toDate();
                let toDate = moment().add(6, 'month').toDate();
                setMaxDate(toDate)
                // getSlots(bookingDate.value)
                let result = await getCalendarStatus(fromDate, toDate)
                if (result) {
                    let unavailable = [];
                    let obj = result.response

                    let not_applicable_date_arr = remainingDates.find(o => o.not_applicable_date_style)
                    let available_date_arr = remainingDates.find(o => o.available_date_style)
                    let unavailable_date_arr = remainingDates.find(o => o.unavailable_date_style)

                    // grey
                    let naDates = obj.UNAVAILABLE;
                    naDates.map(item => {
                        let date = new Date(parseFloat(item))
                        not_applicable_date_arr.not_applicable_date_style.push(date)
                    })

                    // green
                    let remainingDatesArr = obj.REMAINING;
                    remainingDatesArr.map(item => {
                        let date = new Date(parseFloat(item))
                        available_date_arr.available_date_style.push(date)
                    })

                    // red
                    let unavailableDates = obj.FULL;
                    unavailableDates.map(item => {
                        let date = new Date(parseFloat(item))
                        unavailable_date_arr.unavailable_date_style.push(date)
                    })

                    setRemainingDates([not_applicable_date_arr, available_date_arr, unavailable_date_arr])

                    if (result.response.UNAVAILABLE && result.response.UNAVAILABLE.length > 0) {
                        for (let i = 0; i < result.response.UNAVAILABLE.length; i++) {
                            let timestamp = result.response.UNAVAILABLE[i]
                            unavailable.push(moment(new Date(Number(timestamp))).toDate())
                            console.log("date", moment(new Date(Number(timestamp))).format('YYYY-MM-DD'));
                        }
                    }

                    if (result.response.FULL && result.response.FULL.length > 0) {
                        for (let i = 0; i < result.response.FULL.length; i++) {
                            let timestamp = result.response.FULL[i]
                            unavailable.push(moment(new Date(Number(timestamp))).toDate())
                            console.log("date", moment(new Date(Number(timestamp))).format('YYYY-MM-DD'));
                        }
                    }
                    setUnavailableDates(unavailable)
                }
            } else {
                alert('no such booking exist')
            }
        } catch (error) {
            console.log(error)
            if (error == "TypeError: Failed to fetch") {
                error = 'Internal server error'
            }
            notify.show(error, 'custom', 5000, {
                background: '#F55A4E', text: "#FFFFFF"
            });
            console.log('error', error)

        }

    }, []);

    const getBookings = async () => {
        try {
            const response = await bookingService.getBookings(bookedByPhoneNumber);
            const retrievedBookings = response["result"];
            console.log(retrievedBookings);
            setBookings(retrievedBookings);
        } catch (error) {
            console.log(error)
            if (error == "TypeError: Failed to fetch") {
                error = 'Internal server error'
            }
            notify.show(error, 'custom', 5000, {
                background: '#F55A4E', text: "#FFFFFF"
            });
            console.log('error', error)
        }
    }

    const onSubmit = async (e) => {
        try {
            e.preventDefault()
            if (!otp.value) {
                setOTP({
                    value: otp.value,
                    error: 'Please enter OTP'
                })
                return;
            }

            let params = createBookingParams;
            params['otp'] = otp.value

            console.log("params", params);

            // setIsOTPMOdal(false)

            const response = await updateBooking(params);
            console.log(response)
            // notify.show('Your booking is updated', 'success') 


            setIsOTPMOdal(false)
            if (response) {
                let resp = {
                    bookingId: response.savedBooking.bookingId,
                    phone: response.savedBooking.bookedByPhoneNumber,
                    bookingDate: moment(response.savedBooking.slot.fromTime, 'x').format('DD-MMM-YYYY'), //moment(slot.fromTime, 'x').format('hh:mm a')
                    bookingTime: moment(response.savedBooking.slot.fromTime, 'x').format('hh:mm a'),
                    totalPerson: response.savedBooking.male + response.savedBooking.female + response.savedBooking.children,
                    reportingDate: moment(response.savedBooking.slot.reportingTime, 'x').format('DD-MMM-YYYY'),
                    reportingTime: moment(response.savedBooking.slot.reportingTime, 'x').format('hh:mm a')
                }
                setCreatedBookingResponse(resp)
                setIsInfoModal(true)
            }



        } catch (error) {
            console.log(error)
            if (error == "TypeError: Failed to fetch") {
                error = 'Internal server error'
            }
            notify.show(error, 'custom', 5000, {
                background: '#F55A4E', text: "#FFFFFF"
            });
            console.log('error', error)
        }

    }

    const handleDateChange = async (date, isInitialization = false) => {
        console.log("handleDateChange ")
        console.log(date);
        // Fetch slots for the date and show in input

        // let startDate = moment(date).format('YYYY-MM-DD HH:mm:ss');
        // let endDate = moment(date).endOf('day').format('YYYY-MM-DD HH:mm:ss');
        // console.log("startDate " + startDate)
        setBookingDate({
            value: date
        })

        getSlots(date, isInitialization)
    }

    const onOTPChange = (text) => {
        setOTP({
            value: text,
            error: ''
        })
    }

    const onOTPModalClose = () => {
        setIsOTPMOdal(false)
    }

    const getSlots = async (date, isInitialization = false) => {
        try {
            const response = await slotService.getSlotsForDateRange(date, date);
            const retrievedSlots = response["slots"];
            console.log("retrievedSlots", retrievedSlots)

            for (const key in retrievedSlots) {
                // slots.push(retrievedSlots[key]);
                console.log('retrieved slots=========>', retrievedSlots[key]);
                setSlots(retrievedSlots[key])
            }
            if (isInitialization) {
                setStartDate(date);
            }
        } catch (error) {
            console.log(error)
            if (error == "TypeError: Failed to fetch") {
                error = 'Internal server error'
            }
            notify.show(error, 'custom', 5000, {
                background: '#F55A4E', text: "#FFFFFF"
            });
            console.log('error', error)
        }
    }

    const onPhoneChange = (text) => {
        // console.log("onPhoneChange", text)
        // setPhone({
        //     value: text,
        //     error: ''
        // })
        console.log("onPhoneChange", text)
        setPhone({
            value: text,
            error: ''
        })
    }

    const onNameChange = (text) => {
        console.log("onNameChange", text.target.value)
        setName({
            value: text.target.value,
            error: ''
        })
        // setName(text);
    }

    const onSlotSelect = (text) => {
        console.log("onSlotSelect", text)
        if (text == 'Choose...') {
            setSelectedSlot({
                value: '',
                error: ''
            })
        } else {
            setSelectedSlot({
                value: text,
                error: ''
            })
        }
    }

    const onCityChange = (text) => {
        console.log("onCityChange", text)
        setCity({
            value: text,
            error: ''
        })
    }

    const onTypeIDChange = (text) => {
        setTypeIDProof({
            value: text,
            error: ''
        })
    }

    const onIdCardChange = (text) => {
        console.log("onIdCardChange", text)
        setIdCardNUmber({
            value: text,
            error: ''
        })
    }

    const onBookingClick = async (e) => {
        try {
            e.preventDefault()
            console.log("onBookingClick", typeIDProof)
            // alert('clicked')

            if (!name.value) {
                setName({
                    value: name.value,
                    error: 'Please enter name'
                })
                return;
            }

            if (male == 0 && female == 0 && children == 0) {
                setPersonBlankError('Please add person')
                return;
            }

            if (!phone.value) {
                setPhone({
                    value: phone.value,
                    error: 'Please enter phone'
                })
                return;
            }

            if (phone.value.length != 10) {
                console.log("phone value " + phone.value.length)
                setPhone({
                    value: phone.value,
                    error: 'Phone number length should be 10'
                })
                return;
            }

            if (!city.value) {
                setCity({
                    value: city.value,
                    error: 'Please enter city'
                })
                return;
            }

            if (!selectedSlot.value) {
                setSelectedSlot({
                    value: selectedSlot.value,
                    error: 'Please select slot'
                })
                return;
            }

            if (!typeIDProof.value) {
                setTypeIDProof({
                    value: typeIDProof.value,
                    error: 'Please select ID proof'
                })
                return;
            }

            if (!idCardNUmber.value) {
                setIdCardNUmber({
                    value: idCardNUmber.value,
                    error: 'Please enter ID card number'
                })
                return;
            }

            if (idCardNUmber.value.length < 10) {
                setIdCardNUmber({
                    value: idCardNUmber.value,
                    error: 'Please enter valid ID card number'
                })
                return;
            }

            let params = {
                "id": bookingId,
                "bookedByPhoneNumber": phone.value,
                "name": name.value,
                "dateOfBirth": "1994-10-24T18:30:00.000Z",
                "gender": "MALE",
                "idProofType": typeIDProof.value,
                "idCardNumber": idCardNUmber.value,
                "address": "21212121212121212szdcscdsxdcsd",
                "children": male,
                "male": female,
                "female": children,
                "slot": Number(selectedSlot.value),
                "date": bookingDate.value,
                "city": city.value,
            }
            let OTPResponse = await sendOTP({
                bookedByPhoneNumber: phone.value
            })

            console.log("OTPResponse", JSON.stringify(OTPResponse));
            if (OTPResponse) {
                // {"Status":"Success","Details":"dummy - session"}
                params['sessionId'] = OTPResponse.Details
                //open otp modal
                setIsOTPMOdal(true)
                setCreateBookingParams(params)

            }
            notify.show('OTP has sent to your registered phone number', 'success')
        } catch (error) {
            console.log(error)
            if (error == "TypeError: Failed to fetch") {
                error = 'Internal server error'
            }
            notify.show(error, 'custom', 5000, {
                background: '#F55A4E', text: "#FFFFFF"
            });
            console.log('error', error)
        }
    }

    const onOKayClick = (e) => {
        e.preventDefault()
        setIsInfoModal(false)
        window.location.reload()
    }


    const modifiers = {
        disabled: date => 6 === 6, // Disables Saturdays
        highlight: date => 6 === 2 // Highlights Tuesdays
    }

    const modifiersClassNames = {
        highlight: '-highlight'
    }

    return (
        <>
            <div onClick={() => {
                if (openDropDown && isDropAction)
                    setOpenDropDown(false)
            }}>
                <div id="page-container">
                    <div className="main-content-wrap">

                        <main>
                            <section id="booking">
                                <div className="container">
                                    <div className="tab-content" id="myTabContent">
                                        <div className="tab-pane fade show active" id="home" role="tabpanel"
                                            aria-labelledby="home-tab">
                                            <div className="content-wrap">
                                                <form>
                                                    <fieldset className="form-group" id="personal-details">
                                                        <div className="row">
                                                            <div className="col-sm-12">
                                                                <h2 className="section-main">Personal Details (અંગત
                                                                    વિગતો)</h2>
                                                            </div>
                                                            <div className="col-sm-12">
                                                                <div className="form-boxes">
                                                                    <div className="row">
                                                                        <div className="col-lg-6">
                                                                            <div className="form-check">
                                                                                <label htmlFor="name">Name (નામ)</label>
                                                                                <input type="text"
                                                                                    value={name.value}
                                                                                    onChange={e => onNameChange(e)}
                                                                                    className="form-control"
                                                                                    id="name"
                                                                                    aria-describedby="name" />
                                                                                {name.error && <label style={{ color: 'red', textAlign: 'right', alignSelf: 'stretch' }}>{name.error}</label>}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-6">
                                                                            <div className="form-check">
                                                                                <label htmlFor="nop">Number of Person
                                                                                    (વ્યક્તિઓની સંખ્યા)</label>
                                                                                <div className="input-group">
                                                                                    <div className={openDropDown ? "input-group-icon open" : 'input-group-icon'}
                                                                                        id="js-select-special"
                                                                                        onClick={() => setOpenDropDown(!openDropDown)}
                                                                                    >
                                                                                        <input
                                                                                            className="input--style-1 input--style-1-small"
                                                                                            type="text" name="traveller"
                                                                                            value={`${male} Male, ${female} Female, ${children} Children`}
                                                                                            disabled="disabled"
                                                                                            id="info" />
                                                                                        {personBlankError && <label style={{ color: 'red', textAlign: 'right', alignSelf: 'stretch' }}>{personBlankError}</label>}

                                                                                        <i className="zmdi zmdi-chevron-down input-icon" />
                                                                                    </div>
                                                                                    <div className={openDropDown ? "dropdown-select show" : "dropdown-select"}
                                                                                        onClick={() => {
                                                                                            isDropAction = false
                                                                                        }}
                                                                                    >
                                                                                        <ul className="list-room">
                                                                                            <li className="list-room__item">
                                                                                                <ul className="list-person">
                                                                                                    <li className="list-person__item">
                                                                                                        <span
                                                                                                            className="name">Male (પુરુષ
                                                                                                            )</span>
                                                                                                        <div
                                                                                                            className="quantity quantity1">
                                                                                                            <span
                                                                                                                onClick={() => {
                                                                                                                    if (male >= 1) {
                                                                                                                        let maleObj = male - 1
                                                                                                                        setMale(maleObj)
                                                                                                                    }
                                                                                                                }}
                                                                                                                className="minus">-</span>
                                                                                                            <input
                                                                                                                className="inputQty"
                                                                                                                type="number"
                                                                                                                min={0}
                                                                                                                value={male}
                                                                                                                defaultValue={male} />
                                                                                                            <span
                                                                                                                onClick={() => {
                                                                                                                    setPersonBlankError('')
                                                                                                                    let maleObj = male + 1
                                                                                                                    setMale(maleObj)
                                                                                                                }}
                                                                                                                className="plus">+</span>
                                                                                                        </div>
                                                                                                    </li>
                                                                                                    <li className="list-person__item">
                                                                                                        <span
                                                                                                            className="name">Female (
                                                                                                            સ્ત્રી
                                                                                                            )</span>
                                                                                                        <div
                                                                                                            className="quantity quantity1">
                                                                                                            <span
                                                                                                                onClick={() => {
                                                                                                                    if (female >= 1) {
                                                                                                                        let maleObj = female - 1
                                                                                                                        setFemale(maleObj)
                                                                                                                    }
                                                                                                                }}
                                                                                                                className="minus">-</span>
                                                                                                            <input
                                                                                                                className="inputQty"
                                                                                                                type="number"
                                                                                                                min={0}
                                                                                                                value={female}
                                                                                                                defaultValue={female} />
                                                                                                            <span
                                                                                                                onClick={() => {
                                                                                                                    setPersonBlankError('')
                                                                                                                    let maleObj = female + 1
                                                                                                                    setFemale(maleObj)
                                                                                                                }}
                                                                                                                className="plus">+</span>
                                                                                                        </div>
                                                                                                    </li>
                                                                                                    <li className="list-person__item">
                                                                                                        <span
                                                                                                            className="name">Children (બાળકો)</span>
                                                                                                        <div
                                                                                                            className="quantity quantity1">
                                                                                                            <span
                                                                                                                onClick={() => {
                                                                                                                    if (children >= 1) {
                                                                                                                        let maleObj = children - 1
                                                                                                                        setChildren(maleObj)
                                                                                                                    }
                                                                                                                }}
                                                                                                                className="minus">-</span>
                                                                                                            <input
                                                                                                                className="inputQty"
                                                                                                                type="number"
                                                                                                                min={0}
                                                                                                                value={children}
                                                                                                                defaultValue={children} />
                                                                                                            <span
                                                                                                                onClick={() => {
                                                                                                                    setPersonBlankError('')
                                                                                                                    let maleObj = children + 1
                                                                                                                    setChildren(maleObj)
                                                                                                                }}
                                                                                                                className="plus">+</span>
                                                                                                        </div>
                                                                                                    </li>
                                                                                                </ul>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-6">
                                                                            <div className="form-check">
                                                                                <label htmlFor="mob_num">Mobile Number
                                                                                    (મોબાઇલ નંબર)</label>
                                                                                <input type="number"
                                                                                    value={phone.value}
                                                                                    onChange={e => onPhoneChange(e.target.value)}
                                                                                    className="form-control"
                                                                                    id="mob_num"
                                                                                    disabled
                                                                                    aria-describedby="mob_num" />
                                                                                {phone.error && <label style={{ color: 'red', textAlign: 'right', alignSelf: 'stretch' }}>{phone.error}</label>}

                                                                            </div>

                                                                        </div>

                                                                        <div className="col-lg-6">
                                                                            <div className="form-check">
                                                                                <label htmlFor="city">City
                                                                                    (શહેર)</label>
                                                                                <input type="text"
                                                                                    value={city.value}
                                                                                    onChange={e => onCityChange(e.target.value)}
                                                                                    className="form-control"
                                                                                    id="city"
                                                                                    aria-describedby="city" />
                                                                                {city.error && <label style={{ color: 'red', textAlign: 'right', alignSelf: 'stretch' }}>{city.error}</label>}

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </fieldset>
                                                    <fieldset className="form-group" id="darshan">
                                                        <div className="row">
                                                            <div className="col-sm-12">
                                                                <h2 className="section-main">Darshan (દર્શન)</h2>
                                                            </div>
                                                            <div className="col-sm-12">
                                                                <div className="form-boxes">
                                                                    <div className="row">
                                                                        <div className="col-lg-6">
                                                                            <div className="form-check">
                                                                                <label htmlFor="date">Date
                                                                                    (તારીખ)</label>
                                                                                <div className="form-control" >
                                                                                    <DatePicker
                                                                                        selected={bookingDate.value}
                                                                                        onChange={date => handleDateChange(date, true)}
                                                                                        onSelect={date => handleDateChange(date)}
                                                                                        minDate={moment().toDate()}
                                                                                        highlightDates={remainingDates}
                                                                                        excludeDates={unavailableDates}
                                                                                        maxDate={maxDate}
                                                                                        dateFormat={'dd-MMM-yyyy'}
                                                                                    >
                                                                                        <div>
                                                                                            <i class="fa fa-circle" style={{ color: 'green', marginLeft: 10 }}></i><span style={{ color: "green", padding: 5, fontSize: 12 }}>Slot Available</span>
                                                                                            <i class="fa fa-circle" style={{ color: 'red' }}></i><span style={{ color: "red", padding: 5, fontSize: 12 }}>Slot Unavailable</span>
                                                                                        </div>
                                                                                        <div>
                                                                                            <i class="fa fa-circle" style={{ color: 'grey', marginLeft: 10 }}></i><span style={{ color: "grey", padding: 5, fontSize: 12 }}>Not Applicable</span>
                                                                                            <i class="fa fa-circle" style={{ color: 'blue' }}></i><span style={{ color: "blue", padding: 5, fontSize: 12 }}>Current Selected</span>
                                                                                        </div>
                                                                                    </DatePicker>
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-check">
                                                                                <label htmlFor="date">Slot (સમય)</label>
                                                                                <select
                                                                                    onChange={(e) => onSlotSelect(e.target.value)}
                                                                                    className="form-control custom-select"
                                                                                    id="inputGroupSelect03"
                                                                                    aria-label="Example select with button addon"
                                                                                    value={selectedSlot.value}
                                                                                >
                                                                                    <option selected>Choose...</option>
                                                                                    {
                                                                                        slots.length > 0 ?
                                                                                            slots.map((slot) => {
                                                                                                return (
                                                                                                    <option
                                                                                                        disabled={(new Date().getTime() > new Date(parseFloat(slot.fromTime)).getTime()) ? true : (slot.limit - slot.totalSeatsBooked != 0) ? ((slot.limit - slot.totalSeatsBooked) - (male + female + children) < 0 ? true : false) : true}
                                                                                                        key={slot.id}
                                                                                                        value={slot.id}>
                                                                                                        {moment(slot.fromTime, 'x').format('hh:mm A')} -
                                                                                                        {" " + moment(slot.toTime, 'x').format('hh:mm A')}
                                                                                                    </option>
                                                                                                )
                                                                                            }) : (
                                                                                                <option>
                                                                                                    No Slots available today
                                                                                                </option>
                                                                                            )

                                                                                    }
                                                                                </select>
                                                                            </div>
                                                                            <div className="form-check">
                                                                                <label htmlFor="id_num">Photo ID Type
                                                                                    (આધાર કાર્ડ / પાન કાર્ડ / ડ્રાઇવિંગ લાયસન્સ)</label>
                                                                                <select
                                                                                    onChange={(e) => onTypeIDChange(e.target.value)}
                                                                                    className="form-control custom-select"
                                                                                    id="inputGroupSelect03"
                                                                                    aria-label="Example select with button addon"
                                                                                    value={typeIDProof.value}
                                                                                >
                                                                                    {

                                                                                        IDProofs.map((value) => {
                                                                                            return (
                                                                                                <option
                                                                                                    key={value.name}
                                                                                                    value={value.value}>
                                                                                                    {value.name}
                                                                                                </option>
                                                                                            )
                                                                                        })

                                                                                    }
                                                                                </select>
                                                                                {typeIDProof.error && <label style={{ color: 'red', textAlign: 'right', alignSelf: 'stretch' }}>{typeIDProof.error}</label>}
                                                                            </div>
                                                                            <div className="form-check">
                                                                                <label htmlFor="id_num">Photo ID Number
                                                                                    (આધાર કાર્ડ / પાન
                                                                                    કાર્ડ / ડ્રાઇવિંગ લાયસન્સ)</label>
                                                                                <input type="text"
                                                                                    onChange={e => onIdCardChange(e.target.value)}
                                                                                    value={idCardNUmber.value}
                                                                                    className="form-control"
                                                                                    id="id_num"
                                                                                    aria-describedby="id_num" />
                                                                                {idCardNUmber.error && <label style={{ color: 'red', textAlign: 'right', alignSelf: 'stretch' }}>{idCardNUmber.error}</label>}
                                                                            </div>

                                                                        </div>
                                                                        <div className="col-lg-6">
                                                                            <table className="table">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th scope="col">Time Slot (સમય)</th>
                                                                                        <th scope="col">Available (ઉપલબ્ધ)
                                                                                        </th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {
                                                                                        slots.length > 0 &&
                                                                                        slots.map(slot => {
                                                                                            return (
                                                                                                <tr>
                                                                                                    <td scope="col">
                                                                                                        {moment(slot.fromTime, 'x').format('hh:mm A')} -
                                                                                                        {" " + moment(slot.toTime, 'x').format('hh:mm A')}
                                                                                                    </td>
                                                                                                    <td scope="col">
                                                                                                        {slot.limit - slot.totalSeatsBooked} Available
                                                                                                    </td>
                                                                                                </tr>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                        <div className="col-lg-12">
                                                                            <button id="Book Now"
                                                                                onClick={(e) => {
                                                                                    // setModalOpen(true);
                                                                                    onBookingClick(e)
                                                                                }}
                                                                                className="submit-btn">Update (સુધારો
                                                                                કરો)
                                                                            </button>
                                                                            <a id="Book Now"
                                                                                onClick={() => window.location.replace('/')}
                                                                                style={{ marginLeft: 20, backgroundColor: 'grey', color: 'white' }}
                                                                                className="submit-btn"
                                                                            >
                                                                                Cancel (રદ કરો)
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </fieldset>

                                                </form>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="profile" role="tabpanel"
                                            aria-labelledby="profile-tab">
                                            <div className="customer-details">
                                                <div className="row text-center">
                                                    <div className="col-lg-6 ml-auto mr-auto">
                                                        <form>
                                                            <div className="form-check">
                                                                <input
                                                                    value={city}
                                                                    onChange={e => setBookedByPhoneNumber(e.target.value)}
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="customer_email" aria-describedby="city"
                                                                    placeholder="Enter Your Mobile number or Booking ID" />
                                                            </div>
                                                            <div className="form-check">
                                                                <a
                                                                    onClick={() => getBookings()}
                                                                    href="#"
                                                                    // data-toggle="modal"
                                                                    className="submit-btn"
                                                                    data-target="#verify-otp"
                                                                >
                                                                    Submit
                                                                </a>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                bookings.map(booking => {

                                                    return (
                                                        <div className="booking-details-main">
                                                            <div className="booking-wrap">
                                                                <h3>
                                                                    <div className="row justify-content-between">
                                                                        <div className="col-6 col-lg-6">
                                                                            <p><span>Booking ID:</span> <span>{booking.bookingId}</span></p>
                                                                        </div>
                                                                        <div className="col-6 col-lg-6 text-right">
                                                                            <div className="update-details">
                                                                                <a href="#" className="soft"><img src={require('../../images/edit_icon.png')} /> Edit
                                                                                    Booking</a>
                                                                                <a href="#" className="danger"><img src={require('../../images/delete_icon.png')} />
                                                                                    Cancel Booking</a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </h3>
                                                                <div className="booking-detail">
                                                                    <div className="row">
                                                                        <div className="col-6 col-lg-2">
                                                                            <p><span className="user_label light-label">Name <br />(નામ):</span></p>
                                                                        </div>
                                                                        <div className="col-6 col-lg-2">
                                                                            <p><span className="user_name actual-data">{booking.name}</span></p>
                                                                        </div>
                                                                        <div className="col-6 col-lg-2">
                                                                            <p><span className="user_label light-label">Mobile No <br />(મોબાઈલ
                                                                                નંબર):</span></p>
                                                                        </div>
                                                                        <div className="col-6 col-lg-2">
                                                                            <p><span className="user_name actual-data">{booking.bookedByPhoneNumber}</span></p>
                                                                        </div>
                                                                        {/* <div className="col-6 col-lg-2">
                                                                            <p><span className="user_label light-label">Date of Birth <br />(જન્મ
                              તારીખ):</span></p>
                                                                        </div>
                                                                        <div className="col-6 col-lg-2">
                                                                            <p><span className="user_name actual-data">{booking.dateOfBirth}</span></p>
                                                                        </div> */}
                                                                        <div className="col-6 col-lg-2">
                                                                            <p><span className="user_label light-label">No. of Person
                                                                                <br />(વ્યક્તિઓની સંખ્યા):</span></p>
                                                                        </div>
                                                                        <div className="col-6 col-lg-2">
                                                                            <p><span className="user_name actual-data">{booking.personCount}</span></p>
                                                                        </div>

                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-6 col-lg-2">
                                                                            <p><span className="user_label light-label">Booking Date <br />(બુકિંગ
                                                                                તારીખ):</span></p>
                                                                        </div>
                                                                        <div className="col-6 col-lg-2">
                                                                            <p><span className="user_name actual-data">{moment(booking.slot.fromTime, 'x').format('DD-MM-YYYY')}</span></p>
                                                                        </div>
                                                                        <div className="col-6 col-lg-2">
                                                                            <p><span className="user_label light-label">Booking Time <br />(બુકિંગ
                                                                                સમય):</span></p>
                                                                        </div>
                                                                        <div className="col-6 col-lg-2">
                                                                            <p><span className="user_name actual-data">
                                                                                {moment(booking.slot.fromTime, 'x').format('hh:ss A')} - {moment(booking.slot.toTime, 'x').format('hh:ss A')}
                                                                            </span></p>
                                                                        </div>
                                                                        <div className="col-6 col-lg-2">
                                                                            <p><span className="user_label light-label">City
                                                                                <br />(શહેર):</span></p>
                                                                        </div>
                                                                        <div className="col-6 col-lg-2">
                                                                            <p><span className="user_name actual-data">{booking.city}</span></p>
                                                                        </div>

                                                                    </div>
                                                                    {/* <div className="row">
                                                                        <div className="col-6 col-lg-2">
                                                                            <p><span className="user_label light-label">Address
                              <br />(સરનામું):</span></p>
                                                                        </div>
                                                                        <div className="col-lg-10">
                                                                            <p><span className="user_name actual-data">
                                                                                {booking.address}
                                                                            </span></p>
                                                                        </div>
                                                                    </div> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </main>
                        <footer className="text-center">
                            <div className="container">
                                <div className="footerlink">
                                    <ul>
                                        <li><a href="#">Darshan Booking</a></li>
                                        <li><span>|</span></li>
                                        <li><a href="#">Search Your Booking</a></li>
                                    </ul>
                                </div>
                                <div className="copyright">
                                    <p> Copyright Darshan Booking System. All Right Reserved</p>
                                </div>
                            </div>
                        </footer>
                    </div>
                </div>
                {/* Modal Box */}
                {/* Modal */}
                <Modal
                    isOpen={isOTPMOdal}
                    style={customStyles}
                    onRequestClose={onOTPModalClose}
                >
                    <div
                        aria-labelledby="verify-otp-label">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content text-center">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="verify-otp-label">Verify Your OTP</h5>
                                    <p>We have sent you a code via SMS on xxxxxxx{phone.value.substr(7)} for verification</p>
                                </div>
                                <div className="row">
                                    <div className="col-lg-8 ml-auto mr-auto">
                                        <form>
                                            <div className="form-check">
                                                <input type="text" className="form-control" id="customer_email" onChange={(e) => onOTPChange(e.target.value)}
                                                    aria-describedby="city" placeholder="Please enter OTP" />
                                                {otp.error && <label style={{ color: 'red', textAlign: 'right', alignSelf: 'stretch' }}>{otp.error}</label>}

                                            </div>
                                            <div className="form-check">
                                                <button id="submit" className="submit-btn" onClick={(e) => onSubmit(e)}>Verify Now</button>
                                                <p><small>Didn’t receive OTP? <a href="#">Resend Now</a></small></p>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <p>After verification you will receive a message with Booking ID</p>
                            </div>
                        </div>
                    </div>
                </Modal>
                <Modal
                    isOpen={isInfoModal} //isInfoModal
                    style={customStyles}
                // onRequestClose={onOTPModalClose}
                >
                    <div
                        aria-labelledby="verify-otp-label">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content text-center">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="verify-otp-label">Booking Information</h5>
                                    <p><span>Your booking is updated successfully with date </span><span style={{ fontWeight: 'bold' }}>{createdBookingResponse.bookingDate}</span>
                                        <span> on </span><span style={{ fontWeight: 'bold' }}>{createdBookingResponse.bookingTime}</span>
                                        <span> for </span><span style={{ fontWeight: 'bold' }}>{createdBookingResponse.totalPerson}</span><span>{createdBookingResponse.totalPerson == 1 ? ' Person.' : ' Persons.'}</span>
                                    </p>
                                    {/* <p>Your booking is created successfully with date dd-mmm-yyyy on hh:mm a for 10 persons.</p> */}
                                    <p><span>Your booking id is </span><span style={{ fontWeight: 'bold' }}>{createdBookingResponse.bookingId}</span></p>
                                    <p><span>Your reporting time will be </span><span style={{ fontWeight: 'bold' }}>{createdBookingResponse.reportingDate}</span><span> on </span><span style={{ fontWeight: 'bold' }}>{createdBookingResponse.reportingTime}</span><span> at Mankeswar Mandir</span></p>
                                    <p><span>Booking information sent to your mobile number</span></p>
                                </div>

                                <div className="form-check" style={{ marginTop: 20 }}>
                                    <button id="submit" className="submit-btn" onClick={(e) => onOKayClick(e)}>OK</button>
                                    {/* <p><small>Didn’t receive OTP? <a href="#">Resend Now</a></small></p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>

        </>
    )



}
