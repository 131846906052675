import React from 'react';
import { Router, Redirect } from '@reach/router';
import { currentUserSubject } from './_services';
import Home from './pages/Home';
import Darshan from './pages/Darshan';
import AdminLogin from './pages/AdminLogin';

const ProtectedRoute = ({ isAuth, component: Component, ...rest }) =>
  !(currentUserSubject.value === null) ? (
    <Component {...rest} />
  ) : (
      <Redirect from="" to="/" noThrow />
    );

const PublicRoute = ({ component: Component, ...rest }) => (
  <Component {...rest} />
);

function App() {
  return (
    <Router>
      <PublicRoute path="/" default component={Home} />
      <PublicRoute path="/update-booking/:id" component={Darshan} />
      {/* <PublicRoute path="/admin/login" component={AdminLogin} /> */}

      {/* <ProtectedRoute path="/reservation" component={Reservation}>
        <ProtectedRoute path="/status" component={ReservationStatus} />  
        <ProtectedRoute path="/history" component={ReservationHistory} />  
      </ProtectedRoute>

      <ProtectedRoute path="/my-account" component={EditProfile} /> */}
    </Router>
  );
}

export default App;
