import { authHeader, handleResponse } from '../_helpers';
import { server_url } from '.';

export const userService = {
  getAll
};

function getAll() {
  const requestOptions = { method: 'GET', headers: authHeader() };
  return fetch(`api/users/me`, requestOptions).then(handleResponse);
}

export const sendOTP = async (data) => {

  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data)
  };
  return fetch(`${server_url}/api/OTP/sendOTP`, requestOptions).then(handleResponse);
}

